import axios from "axios";

var axiosApi = axios.create();

export const getArticle = () => {
  return axiosApi({
    url: `https://cmsapi.nos.art/showcoinboards`,
    method: "GET",
  });
};

export const getArticleDetail = (id) => {
  return axiosApi({
    url: `https://cmsapi.nos.art/showcoinboards/${id}`,
    method: "GET",
  });
};
