<template>
  <div class="wrap">
    <div class="content">
      <div v-if="!isBack" class="notice">
        <div class="title">{{ $t("notice") }}</div>
        <div class="list">
          <div
            v-for="(item, index) in list"
            :key="index"
            class="item"
            @click.stop="foward(item)"
          >
            <span>{{ item.value }}</span>
            <span>{{ item.data }}</span>
          </div>
        </div>
      </div>

      <div class="detial" v-else>
        <div class="top">
          <svg-icon
            @click.native.stop="isBack = false"
            icon-class="icon-back"
          ></svg-icon>
          <span>{{ $t("back") }}</span>
        </div>
        <!-- <div class="item">
            <span class="title">
              {{$i18n.locale == 'zh' ? currentItem.zh_title : currentItem.en_title }}
            </span>
            <span class="content"  v-html="detailContent">
            </span>
          </div> -->

        <div class="center" v-if="currentItem.type === 'BuyBackFourComplie'">
          <div class="title">
            <span>{{ $t("backRoundFourComplie") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn1") }}</span>
            <span>{{ $t("burnRoundFour-3") }}</span>
            <span>{{ $t("burn3") }}</span>
            <span>{{ $t("burnRoundFour-4") }}</span>
            <span>{{ $t("record") }}</span>
            <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record4"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
            <span>{{ $t("burn5") }}</span>
            <span>ShowFuture Foundation</span>
          </div>
        </div>

        <div class="center" v-if="currentItem.type === 'newAnnounce'">
          <div class="title">
            <span>{{ $t("newAnnounce") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn4-1") }}</span>
            <span>{{ $t("newAnnounce-1") }}</span>
            <span>ShowFuture Foundation</span>
            <span>{{ $t("newAnnounce-data") }}</span>
          </div>
        </div>

        <div class="center" v-if="currentItem.type === 'BuyBack4Complie'">
          <div class="title">
            <span>{{ $t("backRound4Complie") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn4-1") }}</span>
            <span>{{ $t("burnRound4-3") }}</span>
            <span>{{ $t("burn4-4") }}</span>
            <span>{{ $t("burnRound4-1-1") }}</span>
            <span>{{ $t("burnRound4-1-2") }}</span>
            <span>{{ $t("burnRound4-1-3") }}</span>

            <span>{{ $t("burnRound4-5") }}</span>
            <span>{{ $t("burnRound4-6") }}</span>
            <span>{{ $t("burnRound4-7") }}</span>

            <!-- <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record2"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div> -->
            <!-- <span>{{ $t("burn5") }}</span> -->
            <span>ShowFuture Foundation</span>
            <span>{{ $t("burn4-data") }}</span>
          </div>
        </div>
        <div class="center" v-if="currentItem.type === 'BuyBackThrComplie'">
          <div class="title">
            <span>{{ $t("backRoundThrComplie") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn1") }}</span>
            <span>{{ $t("burnRoundThr-3") }}</span>
            <span>{{ $t("burn3") }}</span>
            <span>{{ $t("burnRoundThr-4") }}</span>
            <span>{{ $t("record") }}</span>
            <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record3"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
            <span>{{ $t("burn5") }}</span>
            <span>ShowFuture Foundation</span>
          </div>
        </div>

        <div class="center" v-if="currentItem.type === 'BuyBack3Complie'">
          <div class="title">
            <span>{{ $t("backRound3Complie") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn3-1") }}</span>
            <span>{{ $t("burnRound3-3") }}</span>
            <!-- <span>{{ $t("burn3-2") }}</span>
            <span>{{ $t("burnRound3-4") }}</span>
            <span>{{ $t("record") }}</span> -->
            <!-- <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record2"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div> -->
            <span>{{ $t("burn5") }}</span>
            <span>ShowFuture Foundation</span>
            <span>{{ $t("burn3-data") }}</span>
          </div>
        </div>
        <div class="center" v-if="currentItem.type === 'BuyBackComplie'">
          <div class="title">
            <span>{{ $t("backRound2Complie") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn1") }}</span>
            <span>{{ $t("burnRound2-3") }}</span>
            <span>{{ $t("burn3") }}</span>
            <span>{{ $t("burnRound2-4") }}</span>
            <span>{{ $t("record") }}</span>
            <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record2"
                :key="index"
              >
                <span>{{ item }}</span>
              </div>
            </div>
            <span>{{ $t("burn5") }}</span>
            <span>ShowFuture Foundation</span>
          </div>
        </div>
        <div class="center" v-if="currentItem.type === 'BuyBack2'">
          <div class="title">
            <span>{{ $t("backRound2") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("backRound2-1") }}</span>
            <span>{{ $t("backRound2-2") }}</span>
            <span>{{ $t("backRound2-3") }}</span>
            <span>{{ $t("backRound2-4") }}</span>
          </div>
        </div>
        <div class="center" v-if="currentItem.type === 'web3'">
          <div class="title">
            <span>{{ $t("web3") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("web3-1") }}</span>
            <span>{{ $t("web3-2") }}</span>
            <span>{{ $t("web3-3") }}</span>
            <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in showCoinTransferTx"
                :key="index"
              >
                <span>{{ item.tx }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="center" v-else-if="currentItem.type === 'Burn'">
          <div class="title">
            <span>{{ $t("burnNotice") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("burn1") }}</span>
            <span>{{ $t("burn2") }}</span>
            <span>{{ $t("burn3") }}</span>
            <span>{{ $t("burn4") }}</span>
            <span>{{ $t("record") }}</span>
            <div class="recordList">
              <div
                class="recordItem"
                v-for="(item, index) in record"
                :key="index"
              >
                <span>{{ item.content }}</span>
              </div>
            </div>
            <span>{{ $t("burn5") }}</span>
            <span>ShowFuture Foundation</span>
          </div>
        </div>
        <div class="center" v-else-if="currentItem.type === 'mcshow'">
          <div class="title">
            <span>{{ $t("mc1") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("mc2") }}</span>
            <span>{{ $t("mc3") }}</span>
            <span>{{ $t("mc4") }}</span>
            <span>{{ $t("mc5") }}</span>
            <span>{{ $t("mc6") }}</span>
          </div>
        </div>
        <div class="center" v-else-if="currentItem.type === 'WhitePaper'">
          <div class="title">
            <span>{{ currentItem.value }}</span>
            <span>{{ currentItem.date }}</span>
          </div>
          <div class="CenterContent">
            <span v-if="$i18n.locale === 'en'">
              To better understand the terms of ShowCoin, we update the ShowCoin
              White Paper to Version 1.1. Changes of terms are listed below:
            </span>
            <span>{{ $t("c1") }}</span>
            <span>{{ $t("c2") }}</span>
            <span>{{ $t("c3") }}</span>
            <span v-if="$i18n.locale === 'zh'">{{ $t("c4") }}</span>
            <a v-if="$i18n.locale === 'zh'" :href="paper"
              >https://www.showfuture.foundation/docs/showcoinwhitepaper_cn.pdf</a
            >
          </div>
        </div>
        <div class="center" v-else-if="currentItem.type == 'BuyBack'">
          <div class="title">
            <span>{{ $t("b0") }}</span>
          </div>
          <div class="CenterContent">
            <span>{{ $t("b1") }}</span>
            <span>{{ $t("b2") }}</span>
            <span>{{ $t("b3") }}</span>
            <span>{{ $t("b4") }}</span>
            <span>{{ $t("b5") }}</span>
          </div>
        </div>
        <div class="foot" v-if="currentItem.type === 'WhitePaper'">
          <div @click.stop="upload" class="item">
            <svg-icon icon-class="icon-downLoad"></svg-icon>
            <span>ShowCoin Whitepaper（English）</span>
          </div>
          <div @click.stop="upload" class="item">
            <svg-icon icon-class="icon-downLoad"></svg-icon>
            <span>ShowCoin 白皮书（中文）</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getArticle, getArticleDetail } from "@api";
import MarkdownIt from "markdown-it";
const md = new MarkdownIt();
export default {
  name: "Announcements",
  components: {},
  data() {
    return {
      // list:[],
      isBack: false,
      showCoinTransferTx: [
        {
          tx: "67346d55a246297b361ab8cec251a8bd92086645d98c2d845560d594ac5558e3",
        },
        {
          tx: "8bdfb96f3ae22661420d5310147c6eb92ecedda29057ca266cbeeccacee5f21c",
        },
        {
          tx: "825fce7fd863156b540901dd9f0cb01f4c1e2d305241f2ce705646d806e20c1c",
        },
        {
          tx: "b85fdd88220c7187e4fe90631d5205c219a9ce60dd8f8e54d412b37f323000d2",
        },
      ],
      record4: [
        "70b1637a85a08f22c6ad5153b6931d518aec1c23dd323b36f913137e918ad5cb",
        "e5d91d3d9ca345cbe8fa5aaa04030b0b606d851c94a2674c2ff671065f5fb904",
        "dc07081501a983a7c1d0a14985ac05755d859d58510e4f9f9d72cd7d4f163680",
      ],
      record3: [
        "a544a4656fa74b5aa33bf790ea9cc7ee9648b26d449aa4a05bb4065170b56e7c (10 BSV)",
        "841e226eb88abfe29335896a53bb66e359908bde79615e245a030f2729a28f2e (10 bsv)",
        "15bed11f480ccf0069e7ee0347c9d6e48e154cc649117abf6d5af6d01491b2c9 (10 bsv)",
        "d9f462627e2fd4e971a57ddbdc04ecf0e7df1183434533ee021c4fb53ee43fbe (10 bsv)",
        "4eed4feca46b4159db735a4a35261cb4a47f55079fdfb41a56a467c3b0862d05 (5 bsv)",
        "52e93d1c992295a1e508bafc97aa398712531c8863c03d65e584d4da1e06ebe6 (5 bsv)",
        "ffa5f99c99be3e4f06fecde9e6caff4fcf5738c10736dfe0743aa5359e998905 (5 bsv)",
        "7bbcb66d682314982fab18887b6cc3191e4215685957d4166e94e721a473afc4 (5 bsv)",
        "60239ea1f83343e0e1c5ac8f3ca5a7b091a90422aaacbec310f6ca8ad2f2a1a4 (5 bsv)",
        "a556b8a3b69e84096f03f581e57229e7f06db9776808d8017ef6dff685991b88 (5 bsv)",
        "1c0b4bab2ad9b7946f30b043e563905035be5b3abb8477259577c0469910e52b (5 bsv)",
        "c1f36e471a1ecc12b5eb5561f905f4a5d4e22aa9fd11fdd788d911663d93ceb3 (5 bsv)",
        "7d4079a3ae8766abb88747ef89b70995d32f6ebc36ab99bbdb94604c3aca2101 (5 bsv)",
        "2f2de48ce6dec6d79dba7fc5b6139292146c97b1c361aeb15125e7ca6340a008 (5 bsv)",
        "d1e1a70334a7af5ea0dfaf90b872c3da369a2bd17f4b0277b899378ddc3c08e1 (5 bsv)",
        "e93e28068af7d6631629584ee0f66e538c7db127663fcef3a66dff036c0d00d5 (5 bsv)",
        "d16ad7d0084cc58b6a26b0699dde945b7c7635ab222575e47aa1750f92a944ca (5 bsv)",
        "c6f1e50240da7ca1e9c14690b0c3a73f0e78660688cc3be47b9e6b5d6d581dc7 (5 bsv)",
        "6fea42a20f00cde9528c042ce7ee8bf9d0de7258ce06667e8b207a41e16564c3 (5 bsv)",
        "c02b2afb6929b387118ed2f5882ed017a7a646d9c38e55345178bdd95a87a03f (5 bsv)",
        "5422da8983e6929c7ff15944a56a63ca66a9b6885f1ede098ef3c770a5f5dbb3 (5 BSV)",
        "c1dd76d7a37e7011dbd32ac6b6a3e80d395ee0b41f0ebae7bf68c76b53104175 (8.99 BSV)",
      ],
      record2: [
        "126e5cf07b2ed63a5e5b317eca59df3ac3278c379298eb5a9dc0f4b1b67f3629 (5bsv)",
        "f1029518db31d2a828fec18578ecf114da4bfb3d9977c99ae01038beb0407d41 (3bsv)",
        "ccc4f59288a15a53d7443707e3e22675352cb443ef87609dba32779f66ade500(5bsv)",
        "9eff5057793416761e4320122094a49373d915e0d78247b7bb91c761a918a363 (3bsv)",
        "eb4748be81753aa38f960067598f8455274309fac7b28abc533a652904c209cb （5bsv）",
        "20c2210620c7201a65f76bcd3b080fba0f17291c058859a512012f24e422ce3f（3bsv）",
        "6dcb502ec767bc514ccedfe5582abc8d9657052bd5b8b82d9310c0cff919cdbb （5bsv）",
        "65c3ba868d6f94a4e32069e0830ec3979a754a716c822b981be564126e8bc6ce （3bsv）",
        "c53a505b94e70c1548be853be1f5d328f31977529f8c375d3ea61a2d8ac5dd99 （5bsv）",
        "09f4d7c47e8c65b8cb39ba882c41abd2a2eabb9a31ace7fbf396e17c32d401d3 （3bsv）",
        "911d231c9e78c9929c172a6f5e09f1df93992a6cfd7b1564ef3a642845dc248e （5bsv）",
        "fc2b1113689122f8cc0fdd2da6c82a755939b3406991cc869704b909752dc2dd （3bsv）",
        "323d9be47ee28d3e71f2edb64b318fbb9543a7adbc0fd0ab5b41a3f67749ca48 （10bsv）",
        "ac8e407a0ff5760c8afbda66d563f2e675fc96f52c69f8fc759db85a4d580cb5 （10bsv）",
        "e033df5d61a660324d8eb36176605adb2045d52388be5d79aa1d81894ff4d45e （15bsv）",
        "17991a07d54116f00f370bf96424bb1b0bbd7e403c24d702ddc84951153c1afe （15bsv）",
        "1505f75de45b7253eb2e3eb8808e7b6d6fe76b586a078b43ebe43ab37954ae7a （15bsv）",
        "9c4932676dd7c2eff314692fa4cf0f39673b4e1a201a922701ee4a9ea201b825（15bsv）",
        "1792e73798f54e8d62d524256007bd8c5d31df84aa888d925c14f6e401c6cdb8（15bsv）",
        "eaf1d582116af32773f53897a97dd8ef9067164a091e3827390024c1133d3bc5 （10bsv）",
        "045ba4b47870799abf739390830933053de1686038b2536421eefba7ffb30e7d （10bsv）",
        "a0854acfc39d5edb080f0168c9f9faef86b71a2819142f8123b75b8f020dbebd (50bsv)",
        "845908663825d1156deeb0f16f89b265f63e6c7c9dbeb8d4269189b948c9ec70（58.99）",
        "867fa43bba0b91d247e92e74eb5927931b1696191ef612eb60ce87bbaf801559 （53.8）",
        "d1268a75830d72a8ecfda5e2a0b1226d9e1f496c08681aa74292e11595a8e94f (10bsv)",
        "046903cd017d4c30e26e995faa2a10f156bfb306b18bfd6313844bcb686954b2（10bsv）",
        "55b9e7b628c2da2ff605bdaa8544ddfd900847ed0dc6329ff1c744236ab1db7b （10bsv）",
        "84901c0490ea6cb43b6fb4241a63d146eb34b6302297b351646759f7c3166b09（10bsv）",
        "5b2772f6f86f9eacf438a160fa12ae3cd84ac609432e7337bf40fec9160ba507 (10bsv)",
        "41e2e04720e28c613ae3eb608cde2b8f6e19fca058892d9b6ffe9ff3d8b8c168 (10bsv)",
        "a5728b9849b7e521cde741605c30f1e5c7b9ae5f3a374118a715dfd993a1de39 (10bsv)",
        "59cc37b7cdb867d0ccd2dbb15973f6b0bc4e478bfd0574f0f30d7d0034e25a9f(10bsv)",
        "03c8cc1491b4a8849997ad607c36c1b3e673c41465c1200650bc0a92b6cd4854(10bsv)",
        "19435f60e2f4dd0c56ee2368844575fcf3bc14368ec214fe4802a2b970a9ff6e(10bsv)",
        "241e782905df939b1238b8c8e1c1fb4642879661ff7f170fa184322141257997(10bsv)",
        "70ba8a9745fdbc00241ef3d0fa7b56db8ce41c66e27ab1115fbaa76109da221b(10bsv)",
        "5a4bec251f8d117e8abe15ce4c9b31dd805758b4b654aa0ba9460b294d04eebe(10bsv)",
        "f3ade24bee63b866b8d354f40d3b89a11e7abd364a6acbd4d35a18f2e861d57c(10bsv)",
        "b5df5170071571a5d113a355294501ef5e1625ba71cbdda84639ad142b320400(10bsv)",
        "e5b2de65040b1a67db9b3591ba332a13bec7a496b4abc37fd111513b06c237f5(10bsv)",
        "c78d054025d5437b50d7afd1e0bdb1fd2eb7f0cfde6b5704ce2bf914fdac103d(4.99bsv)",
      ],
      record: [
        {
          content:
            "2abcaed292ea35bca754a6b6f0f3727649e733b29a3ce243abdd3989ac2afade (5bsv)",
        },
        {
          content:
            "50570dac88ce5e756b0c236a6fc98080d866e413a2504ed576b12c872dbaa083 (2bsv)",
        },
        {
          content:
            "ba2d232af818130da660fc30c18f347172fcc69c8c122b8cafa319ee70d9873a (2bsv)",
        },
        {
          content:
            "79bb0e20653531c7b289286913ce1f2f6a2217927f34fe7641fc140e74ca2206 (16.36bsv)",
        },
        {
          content:
            "ed3e4921c863a2a2d5f6e71569457adc51f0f2252a34dc7041ee8e7f66b6cc26 (16.1bsv)",
        },
        {
          content:
            "acc6e50b3e98b447c5507dd1864bf125b1f3ff4ad9f28e855c1f6a91127476f8 (17.58bsv)",
        },
        {
          content:
            "2899b9c8f8eb5d53fe3ee81e855ae317b836e1fbd09c6d308a0657e853775b3a (18.977bsv)",
        },
        {
          content:
            "1199689a063625e289b99082719e07c5fe1898dd853a823bd3c13dfacd0de6ca (17.04bsv)",
        },
        {
          content:
            "e319531cfb823d2e1f8239d4147e561aa973128e35f9fd2b93c4946f80ca1c05 (20bsv)",
        },
        {
          content:
            "d49305d84d2aa32039619a4b3db702a99a89d30747eb044a7e9bea181c9eb6bb (20bsv)",
        },
        {
          content:
            "0efd19b181fee653f23dce69c5fea8f1e200f55bcca63069b2e78e1e9836377c (20bsv)",
        },
        {
          content:
            "ebb2d19eb6f59d26088cba4c77c673c17eccd10663fb55fc3970d855d1ec8059 (2.2 bsv)",
        },
        {
          content:
            "0d22d4ba567ae52e6bff330b058bc1bd4d28b8270995a5a2823304e21d9036c3 (33.6)",
        },
        {
          content:
            "daac2ca8ca60dfc97a58c6dc7e61f3b5d00a3517468ca60c637d0fd36c484595 （20bsv）",
        },
        {
          content:
            "4752cf4e85ceda21c4a0425e1dfe2e8b30bf1f0d0b433f10eeb4e13c14206847 （30bsv）",
        },
        {
          content:
            "f77daa046737e0d086568b071ea2f666dc0a1645cf48749d461fab87876794e5 (60bsv)",
        },
        {
          content:
            "8ee6d4debcbbee77cf0418a49f507a803e82067a2e0dcff650bf3174e46667c8 (49.9bsv）",
        },
        {
          content:
            "bf46c8edd11569cc2f52071193dcdf75494a120b74ecf1a03c592287a795f0ea (40.6bsv)",
        },
        {
          content:
            "677704b8db18d5b2f782f30a5bd48ab4d5d89918c6682db9eab8bcd2e286d6a7 (13bsv)",
        },
        {
          content:
            "5554d926f453bb07cf83767a854c972b67d08d2ee5701853d0bd641f0079936f (30bsv)",
        },
        {
          content:
            "23b53fefdc7088ca589878318cf0ce2635a238c1f70a6c871e82b848998acc99 (20bsv)",
        },
        {
          content:
            "e63df01bdf6adaa81931b5242c3b70d78da5d9759a2d904795f28b6de68e0537 (50bsv)",
        },
        {
          content:
            "fef5546b5715e0f3b45f4e0db245d0e8f09f14356510324ecc578d66e5caf2eb(80bsv)",
        },
        {
          content:
            "43f25feba70becd44b4e198b41184006fd1ee7b7a7062dac160f3db283108bbf(50bsv)",
        },
        {
          content:
            "80e2f3f725028b5df2a67bd5c1744b6d39ea1ac6c668705111c49ebb0be6d011(50bsv)",
        },
        {
          content:
            "d4d1f1611ada5913e17f9d0cefec7b128fddfbc80e38acdd7183d07a1cf543bb (98bsv)",
        },
        {
          content:
            "7173b71c1ddfea9a9ee6f8bab4fc662b0dd12cdd59ed2f89f3da0b4047f0026e （20bsv）",
        },
        {
          content:
            "3de21e1a6f77a378e6dd1babd9cf20b56ccf3f3399bd8a036e240b5309809b87（30bsv）",
        },
        {
          content:
            "442a4fb9307b1c15832f2953716af44a455001a49354fa31c012d2972474ef34（20bsv）",
        },
      ],
    };
  },

  methods: {
    async foward(item) {
      // const res=await getArticleDetail(id)
      // this.currentItem=res.data
      //  this.isBack = true;
      // console.log("aaaa",this.currentItem)
      this.currentItem = item;
      if (item.new) {
        this.isBack = true;
      } else return;
    },
    upload() {
      if (this.$i18n.locale === "zh") {
        window.open("/docs/showcoinwhitepaper_cn.pdf", "blank");
      } else {
        window.open("/docs/showcoinwhitepaper_cn.pdf", "blank");
      }
    },
  },
  computed: {
    // detailContent(){
    //   const content= this.$i18n.locale == 'zh' ? this.currentItem.zh_content : this.currentItem.en_content
    //   return md.render(content);
    // },

    list() {
      return [
        {
          value: this.$t("backRoundFourComplie"),
          date: `2023-01-05`,
          type: "BuyBackFourComplie",
          new: true,
        },
        {
          value: this.$t("newAnnounce"),
          date: `2023-01-02`,
          type: "newAnnounce",
          new: true,
        },
        {
          value: this.$t("backRound4Complie"),
          date: `2022-12-22`,
          type: "BuyBack4Complie",
          new: true,
        },
        {
          value: this.$t("backRoundThrComplie"),
          date: `2022-10-01`,
          type: "BuyBackThrComplie",
          new: true,
        },
        {
          value: this.$t("backRound3Complie"),
          date: `2022-09-20`,
          type: "BuyBack3Complie",
          new: true,
        },
        {
          value: this.$t("backRound2Complie"),
          date: `2022-07-01`,
          type: "BuyBackComplie",
          new: true,
        },
        {
          value: this.$t("backRound2"),
          date: `2022-06-20`,
          type: "BuyBack2",
          new: true,
        },
        {
          value: this.$t("web3"),
          date: `2022-03-29`,
          type: "web3",
          new: true,
        },
        {
          value: this.$t("burnNotice"),
          date: `2022-03-26`,
          type: "Burn",
          new: true,
        },
        {
          value: this.$t("buybackShowCoin"),
          date: `2022-03-14`,
          type: "BuyBack",
          new: true,
        },
        {
          value: this.$t("mcshow"),
          date: `2021-12-14`,
          type: "mcshow",
          new: true,
        },
        {
          value: `ShowCoin White Paper (V.1.1) Update`,
          date: `2021-12-23`,
          new: true,
          type: "WhitePaper",
        },
      ];
    },

    paper() {
      if (this.$i18n.locale === "zh") {
        return `/docs/showcoinwhitepaper_cn.pdf`;
      } else {
        return `/docs/showcoinwhitepaper_en.pdf`;
      }
    },
  },
  async created() {
    // const res=await getArticle()
    // this.list=res.data
    // console.log("list",this.list)
    // console.log("i18",this.$i18n.locale)
  },
  mounted() {},
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
